export default {
    vertFonce: 'rgba(93, 176, 117,1)',
    vertClair: 'rgba(93, 176, 117,0.6)',
    noir: '#1b1b33',
    blanc: 'rgb(250,250,250)',
    rouge: '#C55353',
    gris: '#BDBDBD',
    bleu: '#0000ff',



    signalementRouge:"#FF0000",
    signalementVert:"#40FF00"
};